
@import url('https://fonts.googleapis.com/css2?family=Fascinate+Inline&family=Space+Mono&display=swap');
:root {
  --primary-color:#22223B;
  --secondary-color:#f8f8ff;
  --tertiary-color:#ED5107;
  --dark-primary-theme:#F49D9B;
  --quaternary-color:#d68d10;
  --activeLink-color:#FFD700;
  --card-bgcolor:#F5CAC3;
  --light-card-bgclolor:#84A59D;
}

/* Fonts */


@font-face {
  font-family: 'headings';
  src:url(./fonts/Gliker-Bold.ttf) format('truetype');
  
}


/* body */
body {
  margin: 0;
  padding: 0;  
  box-sizing: border-box;
  background-color: var(--primary-color);
  color:var(--secondary-color);
  font-family: 'Space Mono', monospace;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  text-align: center;
  
}




h3 {
  font-family: 'headings';
  font-weight: 700;
  font-size: xx-large;
  text-align: center;
}



/* Particles css */
.particaljs-container {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: -1;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/**** NavBar *****/
.navbar {
  /* position:fixed; */
  width: 100%;

  overflow: hidden;
  
  justify-content: space-between;
  /* background-color:var(--activeLink-color) ; */

}

.navContainer {
  display: flex;
  /* flex-direction: column; */
  flex: 1;
  height: 100%;
  margin: 0 auto;
  padding: 0 15px;
  z-index: 9999;
  max-width: 1400px;
  font-family: 'Open Sans - bold';
  overflow: hidden; 
}
   .wallhanging {
    width: 100px;
    position: absolute;
    animation: 8s infinite; 
    animation-name: swing;
    top:-30px;
   }

@keyframes swing {
  0%{ transform:rotateZ(8deg)translateZ(0px)}
  50% { transform:rotateZ(-8deg)translateZ(0px);}
  100% { transform:rotateZ(8deg)translateZ(0px)}
}

.theme {
  position: absolute;
  top:75px;
  left:92px;
  width:25px;
  color: var(--primary-color);
}

@media (min-width:3840px) {
  .navContainer {
    max-width:0px;
    width:80px;

  }
  .wallhanging {
    width: 250px;
    position: absolute;
    animation: 8s infinite; 
    animation-name: swing;
    top:-50px;
   }

  .theme {
    
    top:14em;
    left:83em;
  }

  .section-1 {
    margin-top: 20em;
  }
  .hero-main {
    margin-block: 20em;
  }
}

@media (max-width:2048px){
  .theme {
    left:370px;
  }
}

@media (max-width:1920px){
  .theme {
  
    left:301px;
  }
}
@media (max-width:1902px){
  .theme {
    
    left:295px;
  }
}
@media (max-width:1896px){
  .theme {
    
    left:285px;
  }
}
@media (max-width:1877px){
  .theme {
    
    left:275px;
  }
}
@media (max-width:1846px){
  .theme {
    
    left:258px;
  }
}
@media (max-width:1836px){
  .theme {
    
    left:248px;
  }
}
@media (max-width:1800px){
  .theme {
    
    left:238px;
  }
}
@media (max-width:1792px){
  .theme {
    
    left:230px;
  }
}
@media (max-width:1753px){
  .theme {
    
    left:218px;
  }
}
@media (max-width:1729px){
  .theme {
    
    left:198px;
  }
}
@media (max-width:1711px){
  .theme {
    
    left:188px;
  }
}
@media (max-width:1686px){
  .theme {
    
    left:175px;
  }
}
@media (max-width:1640px){
  .theme {
    
    left:160px;
  }
}
@media (max-width:1606px){
  .theme {
    
    left:140px;
  }
}
@media (max-width:1593px){
  .theme {
    
    left:130px;
  }
}
@media (max-width:1573px){
  .theme {
    
    left:118px;
  }
}
@media (max-width:1533px){
  .theme {
    
    left:98px;
  }
}
@media (max-width:1493px){
  .theme {
    
    left:78px;
  }
}
@media (max-width:1479px){
  .theme {
    
    left:68px;
  }
}
@media (max-width:1368px){
  .theme {
    left:56px;
  }
}

@media (max-width:1261px){
  .theme {
    position: absolute;
    top:75px;
    left:58px;
    width:25px;
    color: var(--primary-color);
  }
}

.navElements ul {
  /* flex: 1; */
 /* display: flex; */
 justify-content: space-evenly;
 list-style-type: none;
 top: 50%;
position: fixed;
padding: 0px;
/* background-color: var(--activeLink-color); */
border-radius: 30px;
height: 250px;
width: 70px;
opacity: 0.9;
left: 93%;
z-index: 1;
align-items: center;
justify-content: center;
border: 2px solid var(--light-card-bgclolor);
border-style: dashed;

}

@media (max-width:1147px){
  .navElements ul {
    left: 92%;
  }
}

.navElements li {
  margin-block: 25px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.navElements ul a {
 
  font-family:Poppins-Regular ;
  font-size: x-large;
  font-weight: 900;
  text-decoration: none; 
  color:var(--light-card-bgclolor);
  background-color: transparent;
}

/* mail tooltip */
.mail-icon{
  position: relative;
  display: inline-block;
  
}

.mail-icon .tooltiptext {
  visibility: hidden;
  width: 130px;
  background-color:var(--activeLink-color);
  color:var(--primary-color);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: small;
  margin: 4px;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  width: 80px;
  bottom: 100%;
  left: 130%;
  margin-left: -60px;
}

.mail-icon:hover .tooltiptext {
  visibility: visible;
  
}

/* download tooltip */
.download-icon{
  position: relative;
  display: inline-block;
}

.download-icon .tooltiptext {
  visibility: hidden;
  width: 130px;
  background-color:var(--activeLink-color);
  color:var(--primary-color);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: small;
  margin: 4px;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  width: 80px;
  bottom: 100%;
  left: 130%;
  margin-left: -60px;
}

.download-icon:hover .tooltiptext {
  visibility: visible;
}

/* linkedin tooltip */
.linkedin-icon{
  position: relative;
  display: inline-block;
}

.linkedin-icon .tooltiptext {
  visibility: hidden;
  width: 130px;
  background-color:var(--activeLink-color);
  color:var(--primary-color);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: small;
  margin: 4px;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  width: 80px;
  bottom: 100%;
  left: 130%;
  margin-left: -60px;
}

.linkedin-icon:hover .tooltiptext {
  visibility: visible;
}

/* github tooltip */
.github-icon{
  position: relative;
  display: inline-block;
}

.github-icon .tooltiptext {
  visibility: hidden;
  width: 130px;
  background-color:var(--activeLink-color);
  color:var(--primary-color);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: small;
  margin: 4px;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  width: 80px;
  bottom: 100%;
  left: 130%;
  margin-left: -60px;
}

.github-icon:hover .tooltiptext {
  visibility: visible;

}

.navElements ul a.active::after {
  content: '';
  position:absolute;
  bottom: -4px;
  left: 0;
  width: 300px;
  height: 2px;
  /* background-color: var(--secondary-color); */
}



.nav-link {
  color: var(--activeLink-color);
  background-color: transparent;
  margin-block: 10px;
}

@media (max-width:991px) {
  .navElements ul {
    left:91%;
  }
}

@media (max-width:646px) {
  .navElements ul {
    margin-top: 72%;
  }
}

@media (max-width:855px) {
  .navElements  {
   display: none;
  
  }
  }

/* Home Page  */
.section-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-block: 115px; 
  margin-inline: 90px;  
  animation: fadeIn 5s; 
  /* clip-path: polygon(0px 0px, 100% 0px, 100% 75%, 0px 94%); */
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.hero-main {
  flex: 1;
  margin-block:50px;
  margin-inline: 70px;
}

.wave {
  animation-name: wave-animation;
  animation-duration: 2.5s;  
  animation-iteration-count: infinite;  /* Never stop waving :) */
  transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
  display: inline-block;

}

@keyframes wave-animation {
  0% { transform: rotate( 0.0deg) }
 10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
 20% { transform: rotate(-8.0deg) }
 30% { transform: rotate(14.0deg) }
 40% { transform: rotate(-4.0deg) }
 50% { transform: rotate(10.0deg) }
 60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
100% { transform: rotate( 0.0deg) }
}


.hero-heading {
  font-family:'headings';
  font-size: xxx-large;
  font-weight: 800;
  margin-bottom: 2px;
  word-spacing: 10px;
  
}

.hero-subtitle {
  font-family: 'Space Mono', monospace;
  font-size: x-large;
  font-weight: 200;

}

.hero-para {
  font-size: large;
  letter-spacing: 1;
  line-height: 1.5;
  margin-bottom: 40px;
  
}

.hero-Img {
  flex-direction: row;
  flex: 1; 
  margin-block: 50px;
  margin-inline: 40px;
  /* padding-left: 30px; */
}

.main-img {
  width: 350px;
  border-radius: 40px;
  height:auto;
  z-index: 1;
  margin-top: 60px;
}


.hero-btn {
  color: var(--primary-color);
  background-color: var(--activeLink-color);
  font-size: medium;
  text-decoration: none;
  padding: 15px 40px;
  border-radius: 4px;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
  margin-top: 10px;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.chat-icon{
  color: var(--activeLink-color);
  font-size: larger;
  color:var(--primary-color);
}

.hero-btn a {
  background-color: transparent;
  margin: 5px;
  size: 9x;
}

 .hero-btn:hover { 
  font-size: larger;
  box-shadow: 2px 15px 20px rgba(242, 242, 242, 0.4);
 }


/* hexagone skills cards */

.skill-hexa-card {
  /* position: absolute; */
  display:flex;
  flex-direction: row;
  flex: 1;
  margin-top: 50px;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center; 
}
.hexa-flip-card {
  position: relative;
  width: 85px;
  height: 68px;
  cursor: pointer;
  border: none;
  perspective: 1000px; /* Defines the distance between the z=0 plane and the user in order to give the 3D perspective effect */
}
.hexa-flip-card span {
  display: inline-block;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 50px;

}

.hexa-flip-card-back-p {
  text-align: center;
  font-size: x-small;
  padding:1px;
  margin-block: 3px;
  margin-inline: 5px;
}

.hexa-flip-card-inner  {
  position: absolute;
  width: 70%;
  height: 100%;
  margin-top: 0px;
  text-align: center;
  transition: transform 0.6s; /* Apply smooth transition to the flipping effect */
  transform-style: preserve-3d; /* Ensures the children of the element are rendered in a 3D space */
  }

.hexa-flip-card:hover .hexa-flip-card-inner {
  transform: rotateY(180deg); 
}

.hexa-flip-card-front, .hexa-flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.hexa-flip-card-front {
  background: var(--activeLink-color);
  color: var(--primary-color);
  clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  margin-top: 0px;
}

.icons {
  font-size: small;
  margin-top: 2px;
  text-align: center;
}
.hexa-flip-card-back{
  background: var(--activeLink-color);
  color:var(--primary-color);
  transform: rotateY(180deg);
  clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  
}
@media (max-width:1920){
  .section-1 {
    margin-block: 350px;
  }
}

@media (max-width:1165px) {
  .section-1 {
    margin-block: 150px;
  }
  .hero-main {
    margin-inline: 40px;
  }

  .hero-Img {
    margin-inline: 30px;
  }
  .main-img {
    width: 320px;
    height: 80%;
    margin-top: 60px;
  }
}

@media (max-width:1135px) {
  .hero-main {
    flex: 1;
    margin-block:30px;
    margin-inline: 30px;
  }
  
}

@media (max-width:1024px){
  .section-1 {
    animation: none; 
  }
}

@media (max-width:1000px) {
  .section-1 {
    margin-block: 90px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .hero-main {
    margin-block: 10px;
  }
  .hero-Img {
    margin-inline: 10px;
  }
  .main-img {
    width: 300px;
    height: auto;
    margin-top: 50px;
  }

  .skill-hexa-card {
    align-items: center;
    justify-content: center;
  }
  
}

@media (max-width:746px){
  .section-1 {
   margin-block: 150px;
   margin-inline: 0px;
  }
}

@media (max-width:655px){
  .hero-Img {
    margin-bottom: 30px;
  }
}

@media (max-width:518px){
  .hero-para {
    margin-inline: 50px;
  }
  
}

@media (max-width:496px) {
  .hero-btn{ 
    padding: 15px 20px;
   }
   .skill-hexa-card  {
    margin-inline: 10px;
  }
  .hexa-flip-card {
    width: 75px;
    height: 69px;
  }

 }

 @media (max-width:496px) {
  .skill-hexa-card  {
    margin-inline: 0px;
  }
  }
/* 
  @media (max-width:416px) {
    .skill-hexa-card  {
      margin-left: 3px;
    }
    } */




/**** About Page ****/

.mainContainerImg {
  width: 100%;
  height:auto;
  opacity: 0.7;
  margin-inline: 30px;

}

.text-overley {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.7;
  
}


/* Projects section */
.vertical-timeline-element-content {
  margin-bottom: 3px;
}

.vertical-timeline-element-content.bounce-in  {
  background-color: transparent;
}

.vertical-timeline-element-content p {
  margin-bottom: 3px;
  background-color: var(--activeLink-color);
  -webkit-box-shadow: 0 ; 
  box-shadow: 0 0pc 0 var(--activeLink-color);
} 


@media (max-width:1157px) {
  .vertical-timeline-element {
    margin-inline: 50px;
  }
}

.projectsanddevelopment {
  align-items: center;
  justify-content: center;
 text-align: center;
}

.container {
  display: flex;
}
.project-leng {
  flex: 1;
  flex-direction: row;
  text-align: center;
  padding: 5px;
  margin-block: 2px;
  margin-inline: 2px;
  font-size: medium;
  background-color:lightgrey;
  font-weight: 100;
  border-radius: 20px;
  width: 90px;
  height: 30px;
}
.container p {
  margin-bottom: 20px;
}

span {
  flex-direction: row;
}

a {
  text-align: center;
  padding: 2px;
  font-size: medium;
  font-weight: 100;
  width: 250px;
  height: 300px;

}

/* Work exprience Timeline  */

/* down arrow section */
.down-arrow-section {
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-block: 60px;
  animation: bounce ;
  animation-duration: 4s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
  animation-iteration-count: infinite;
}

.down-arrow {
  margin-block: 100px;
  font-size: xx-large;

}
@keyframes bounce{
  0%   { transform: translateY(0); }
  50%  { transform: translateY(-50px); }
  100% { transform: translateY(0); }
}

@media (max-width:1920){
  .down-arrow-section {
    margin-block: 80px;
  }
  .down-arrow {
    margin-block: 150px; 
  }
}


/* Journey Timeline */
.vertical-timeline-element-content {
  position: relative;
  margin-left: 60px;
  padding: 1em;

}

 .vertical-timeline-element-content-arrow  {
 display: none;
}

.vertical-timeline-element {
  align-items: center; 
}

.flip-card {
  position: relative;
  background-color: transparent;
  /* width: 300px; */
  height: 370px;
  width: 280px;
  perspective: 1000px;
  margin-block: 40px;
  align-items: center;
  justify-content: center;
  text-align: center;
  
  
}


.main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-block: 150px;
  /* margin-bottom: 40%;; */
  margin-bottom: 8px;
}

@media(max-width:2048px){
  .main {
  margin-top: 12em;
  }

  .down-arrow-section {
    margin-block: 100px;
  }
}

@media(max-width:1920px){
  .main {
  padding-block: 240px;
  }
}

.leftSide {
  display: flex;
  flex:1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-inline: 20px;
  margin-top:100px;
  margin-bottom: 15px;
 
}

.rightSide {
  display: flex;
  flex:1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-inline: 20px;
  /* margin-top: 100px; */
  margin-bottom: 15px;
  
}

.vl {
  border-left: 2px solid var(--activeLink-color);
  height: 150%;
  position: absolute;
  left: 50%;
  margin-left: -3px;
  margin-block: 20%;

}

.title {
   position: absolute;
    border: none;
    background:var(--activeLink-color);
    color: var(--primary-color);
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.4em;
    padding: 20px;
    /* margin-top: 40%; */
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 50px;
}


.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
 
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back { 
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
 
}

.flip-card-front {
  background-color: var(--activeLink-color);
  color: var(--primary-color);
  display: flex;
  flex-direction: column;
 
  
}

.flip-card-back {
  display: flex;
  background-color: var(--activeLink-color);
  color: var(--primary-color);
  transform: rotateY(180deg);
 
}


.timeline-element-title {
    display: flex;
    font-size: xx-large;
    margin: 30px;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.timeline-element-subtitle {
  font-size: large;
  text-align: center;
  margin-top: 8px;
}

.timeline-element-para {
margin-top: 0px;
}

.dateFromTo {
  flex: 1;
  color: gray;
}

@media (max-width:996px){
  .vl {
    display: none;
  }

  .title {
    margin-top: 0px;
    margin-bottom: 2400px;
  }

  .main {
    flex-direction: column;
  }
  .flip-card {
    height: 350px;
    width: 320px;
    perspective: 1000px;
    margin-block: 40px;
    align-items: center;
    justify-content: center;
    text-align: center;
    
  }
}

@media  (min-width: 750px){
.vertical-timeline.vertical-timeline--two-columns:before {
    left: 50%;
    margin-left: -2px;
}
.vertical-timeline--two-columns .vertical-timeline-element-icon {
    width: 60px;
    height: 60px;
    left: 50%;
    margin-left: -30px;
}
}
@media (max-width:503px){
  .timeline-element-title {
    font-size: x-large;
    margin: 20px;
  
}
}

/* card backside  */

.timeline-list {
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: scroll;
  letter-spacing: 1;
  
}


.rolesAndResponsibilities {
  margin-top: 8px;
  font-size: large;
  text-align: left;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  margin-bottom: 2px;
  

}

@media (max-width:565px) {
  .rolesAndResponsibilities {
    font-size: medium;
    font-weight: 800;
    margin-right: 2px;
  } 
}

@media (max-width:445px) {
  .rolesAndResponsibilities {
    font-size: small;
  } 
}


/* Project Section */

.skill-title {
  margin-top: 150px;
  margin-bottom: 10px;  
}
.project-section {
  height: 700px;
  /* margin-inline: 50px; */
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-block: 50px;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
   
}

.projects {
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* margin-inline: 50px; */
  margin-left: 60px;
  

}

.skills-dec {
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: transparent;
  /* margin-inline: 20px; */
  /* margin-inline: 30px; */
} 


.swiperImg  {
  width: 700px;
  height: 480px;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-inline: 50px;
  /* margin-inline: 50px; */
  /* margin-block: 10px; */

}

.swiperDec  {
  width: 350px;
  height: 450px;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-inline: 50px;
  /* margin-inline: 50px; */
  /* margin-block: 10px; */
}

.swiper-cards {
  margin: 0px;
  
}

.swiper-slide {
 
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color:var(--primary-color);
  overflow: hidden;
 
}


.swiper-slide:nth-child(1n) {
  background-color: var(--secondary-color);
}

.swiper-slide:nth-child(2n) {
  background-color: var(--secondary-color);
}

.swiper-slide:nth-child(3n) {
  background-color: var(--secondary-color);
}

.swiper-slide:nth-child(4n) {
  background-color: var(--secondary-color);
}

.swiper-slide:nth-child(5n) {
  background-color: var(--secondary-color);
}

.swiper-slide:nth-child(6n) {
  background-color: var(--secondary-color);
}

.swiper-slide:nth-child(7n) {
  background-color: var(--secondary-color);
}

.swiper-slide:nth-child(8n) {
  background-color: var(--secondary-color);
}


.Img1, .Img2, .Img3, .Img4, .Img5 {
  height:100%;
  width:100%;
  border-radius: 15px;
  background-color: transparent;
  /* margin-top: 2px; 
   margin-right: 20px; */
   /* margin-block: 20px; */

}


.RecipeImg, .VoyageImg, .JuggernutImg, .SayitImg, .HabitualImg {
  height:auto;
  width:100%;
  border-radius: 15px;
  background-color: transparent;
  /* margin-top: 2px; 
   margin-right: 20px; */

}

/* Skills rightside content */

.r-main {
  color:var(--primary-color);
  background-color:transparent;
  /* border-top: 2px solid white;
  border-bottom: 2px solid white; */
}
.r-main-tilte {
  color: var(--primary-color);
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 20px;
  text-decoration: underline;
}
.r-main-para {
  font-size: medium;
  font-weight: 500;
  color: var(--primary-color);
  margin-top: 10px;
  line-height: 2;
  margin-inline: 10px;
  height: auto;
  margin-bottom: 30px;
  
}

.r-swiperSlide:nth-child(1n) {
  background-color: #ffffff;
}
.r-swiperSlide:nth-child(2n) {
  background-color: #128983;
  color: var(--primary-color);
}
.r-swiperSlide:nth-child(3n) {
  background-color: ghostwhite;
}
.r-swiperSlide:nth-child(4n) {
  background-color:#DD995D;
}
.r-swiperSlide:nth-child(5n) {
  background-color: #ABC9E9;
}


.demo-links  {
  color:var(--primary-color);
  /* background-color:lightgrey; */
  text-decoration: none;
  margin: 8px;
  top:5px;
  font-size: larger;
  font-weight: 400;
  width: 250px;
  height: 300px;
  border-radius: 20px solid var(--primary-color);
} 

.demo-links :hover  {
  color:red;
  font-size: xx-large;
}

.swiper-arrows {
  display: none;
}

@media (max-width:1261px) {

  .project-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 1000px;
    overflow: hidden;
  }
  .projects {
    margin-right: 0px;
    margin-left: 0px;
    align-items: center;
    justify-content: center;
    text-align: center;

   
  }

  .skills-dec {
    align-items: center;
    justify-content: center;
    text-align: center;

  }

  .swiperImg  {
    width: 500px;
    height: 450px;

  }
  
  .swiperDec  {
    width: 350px;
    height: 450px;

  }
  
}

@media (max-width:873px) {
  .swiperImg  {
    width: 550px;
    height: 450px;
  }
}

@media (max-width:750px) {
  .swiperImg  {
    width: 450px;
    height: 400px;
  }
}
@media (max-width:692px) {
  .projects {
    margin-bottom: 0px;
    margin-left: 0px;
}
  .skills-dec {
    margin-top: 0px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .swiperImg  {
    width: 400px;
    height: 350px;
  }

  .swiperDec  {
    width: 300px;
    height: 450px;
  } 
}

@media (max-width:634px){

  .projects {
    margin-right: 80px;
 
}
.skills-dec {
  margin-right: 80px;

}

}

@media(max-width:599px){

  .project-section {
    margin-inline: 0px;
  }
  .swiperImg  {
    width: 350px;
    height: 350px;
  }

  .skills-dec {
    margin-top: 10px;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: 10px;
  
  }
}

@media(max-width:530px) {
  .projects {
    margin-right: 0px;
    align-content: center;
    justify-content: center;
    text-align: center;
 
}
.skills-dec {
  margin-right: 0px;
  align-content: center;
    justify-content: center;
    text-align: center;

}
  .swiper-wrapper {
    overflow: hidden ;
  }

  .swiper-arrows {
    display: block;
    margin-top: 10px;
    font-size: x-large;
    font-weight: 800;
    
  }
  .left-arrow, .right-arrow{
    color: var(--dark-primary-theme);
    margin-inline: 10px; 
  }
}



/* Contact Section */

.contact {
  height: 50rem;
  width: 100%;
  background-color:#fcf7ef;
  clip-path: polygon(0px 30%, 100% 0px, 100% 100%, 0px 100%);
  overflow: hidden;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 150px;
}

.contact-form {
  font-family: 'Space Mono', monospace;
  width: 500px;
  height: auto;
  margin-inline: 50px;
  margin-block:50px ;
  justify-content: center;
  align-items: center;
}


.backgroundImg {
width: auto;
flex: 1;
display: none;
}

.contact-title {
  font-family: 'headings';
  font-weight: 700;
  font-size: xx-large;
  color: var(--primary-color);
}

input[type=text], textarea {
  font-family: 'Space Mono', monospace;
  color: var(--primary-color);
  width: 100%;
  padding: 12px;
  border: 2px solid var(--primary-color);
  border-radius: 8px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  font-size: large;
  margin-block: 10px;
}

textarea {
  height: 180px;
}

.form-btn {
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-block: 10px;
}

input[type=submit] {
  font-family: 'Space Mono', monospace;
  width:150px;
  font-size: large;
  background-color: var(--activeLink-color);
  color: var(--primary-color);
  padding: 10px 18px;
  border: none;
  border-radius: 4px;
  cursor: pointer;    
  transition: all 0.2s ease-in-out;
}

input[type=submit]:hover {
  box-shadow: 2px 15px 20px rgb(131, 111, 14);
}
/* footer */

.footerElements{
  display: none;
}

@media(max-width:855px){
  .footerElements{
    background-color: var(--activeLink-color);
    padding-block: 10px;
    display: block;
    
  }
 
  .footer-ul {
    /* background-color: var(--activeLink-color); */
    display: flex;
    flex-direction: row;
    text-decoration: none;
    justify-content: center;

  }
  
  .footer-ul li {
    list-style: none;
    align-items: center;
    text-align: end;
    margin-inline:10px;
    margin-top: 20px;
    
  }

  .footer-ul a {
    font-size: x-large;
    color: var(--primary-color);
  }
  
  .copyright-p {
    font-size: large;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--primary-color);
    margin-left:20px;
  }
}
